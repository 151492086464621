.custom-listeners-landpro-table {
    overflow: hidden;
    min-width: 100%;
    border: 0;
    border-spacing: 0;
    table-layout: fixed;
    opacity: 1;
    transition: opacity 150ms linear;
    font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

.custom-listeners-landpro-tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}

.custom-listeners-landpro-td {
    text-align: left;
    position: relative;
    box-sizing: border-box;
    padding: 0;
    min-width: 70px;
    height: 45px;
    vertical-align: middle;
    transition: background-color .3s cubic-bezier(.000,.465,.750,.750);
    background-color: #fff;
    border-bottom: 1px #edeef0 solid;
}

.custom-listeners-landpro-td-inner-div {
    display: flex;
    padding: 10px;
}
