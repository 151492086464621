.custom-listeners-landpro-tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}

.custom-listeners-landpro-td {
    text-align: left;
    position: relative;
    box-sizing: border-box;
    padding: 0;
    min-width: 70px;
    height: 45px;
    vertical-align: middle;
    transition: background-color .3s cubic-bezier(.000,.465,.750,.750);
    background-color: #fff;
    border-bottom: 1px #edeef0 solid;
}

.custom-listeners-landpro-td-inner-div {
    display: flex;
    padding: 10px;
}

.custom-listeners-landpro-td-inner-div-link {
    display: flex;
    padding: 10px;
    cursor: pointer
}
